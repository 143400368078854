<template>
    <ion-card>
        <ion-card-header>
            <ion-card-title class="card-title-style">
                {{ $t('account-information') }}
            </ion-card-title>
        </ion-card-header>

        <ion-card-content v-if="user" class="no-padding-bot">
            <div class="row-content">
                <ion-label class="ion-text-capitalize text-body2-style">User ID</ion-label>
                <ion-label class="text-body2-style">{{ user.user_hash_id }}</ion-label>
            </div>
            <div class="row-content">
                <ion-label class="ion-text-capitalize text-body2-style">
                    {{ $t('email') }}
                </ion-label>
                <ion-label class="text-body2-style">
                    {{ user ? user.email : ' ' }}
                </ion-label>
            </div>

            <div class="row-content">
                <ion-label class="ion-text-capitalize text-body2-style">
                    {{ $t('account-type') }}
                </ion-label>
                <ion-label class="ion-text-capitalize text-body2-style">{{
                    user && user.is_premium ? $t('premium') : $t('free')
                }}</ion-label>
            </div>
            <div>
                <ion-button @click="gotoPremium" aria-label="Go Premium" color="primary" fill="clear" v-if="platform !== 'web' && user && !user.is_premium">
                    <ion-label class="text-space-right ion-text-capitalize button-text">
                        {{ $t('up-premium') }}
                    </ion-label>
                </ion-button>
                <ion-button expand="full" class="ion-margin-top full-btn-text" color="primary" @click="logout">
                    {{ $t('sign-out') }}
                </ion-button>
            </div>
            <ion-button expand="full" class="ion-margin-top full-btn-text" color="danger" @click="deleteAccount">{{
                $t('delete_account')
            }}</ion-button>
        </ion-card-content>
    </ion-card>
</template>
<script>
import { mapState } from 'vuex'
import authApi from '../../util/apis/auth'
import authSrv from '@/util/services/authService'
import useNavigator from '@/composables/useNavigator'
import appService from '../../util/services/appService'

export default {
    name: 'AccountInforCard',
    props: {
        haveCreditInfor: Boolean,
    },
    data: function () {
        return {}
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            platform: (state) => state.platform,
        }),
    },
    methods: {
        async logout() {
            authSrv.signOut()
        },
        async gotoPremium() {
            let result = await useNavigator.goToPremiumUpgrade()
            if (result && result.purchased) {
                await authApi.refreshUserInfo()
                console.log('PURCHASED')
            }
        },
        async deleteAccount() {
            const role = await appService.presentAlert({
                header: 'Delete Account',
                message: 'Did you want to permanently delete your account? ',
                buttons: [
                    {
                        text: 'Cancel',
                        role: 'cancel',
                    },
                    {
                        text: 'OK',
                        role: 'ok',
                    },
                ],
            })
            if (role === 'ok') {
                authSrv.deleteAccount(this.haveCreditInfor)
            }
        },
    },
}
</script>
<style scoped>
.row-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    margin-bottom: 20px;
}

.row-btn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

ion-button {
    --background: none;
    --padding-start: none;
}
.button-text {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-transform: capitalize;
    cursor: pointer;
}
.full-btn-text {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-transform: capitalize;
    cursor: pointer;
}
ion-card {
    padding-bottom: 15px;
}
.text-space-right {
    margin-right: 12px;
}
.no-padding-bot {
    padding-bottom: 0;
}
</style>
