<template>
    <ion-page>
        <simple-header-toolbar :titleName="$t('settings')" :showBackBtn="false"></simple-header-toolbar>
        <ion-progress-bar type="indeterminate" v-if="loading"></ion-progress-bar>
        <ion-content>
            <account-infor-card :haveCreditInfor="haveCreditInfor"></account-infor-card>
            <credit-infor-card
                :haveCreditInfor="haveCreditInfor"
                :creditCardCallBack="creditCardCallBack"
                v-on:change-credit="changeCredit"
            ></credit-infor-card>
            <favorite-operator-card></favorite-operator-card>
            <notification-setting-card></notification-setting-card>
            <app-information-card></app-information-card>
        </ion-content>
    </ion-page>
</template>

<script>
import { mapState } from 'vuex'
import AccountInforCard from '../card/AccountInforCard'
import FavoriteOperatorCard from '../card/FavoriteOperatorCard'
import NotificationSettingCard from '../card/NotificationSettingCard.vue'
import AppInformationCard from '../card/AppInformationCard'
import CreditInforCard from '../card/CreditInforCard'
import stripeApi from '../../util/apis/stripe'
import SimpleHeaderToolbar from '../../components/toolbar/SimpleHeaderToolbar'
import appService from '../../util/services/appService'
import inAppPurchaseService from '../../util/services/inAppPurchaseService'

export default {
    name: 'Settings',
    components: {
        AccountInforCard,
        FavoriteOperatorCard,
        AppInformationCard,
        CreditInforCard,
        NotificationSettingCard,
        SimpleHeaderToolbar,
    },
    data: function() {
        return {
            haveCreditInfor: false,
            loading: false,
        }
    },
    mounted: async function() {
        this.haveCreditInfor = Boolean(await stripeApi.checkStripeDirectDebitInfor())
        await inAppPurchaseService.reCheckUserSignedPremium()
    },
    computed: {
        ...mapState({
            user: state => state.user,
        }),
    },
    methods: {
        changeCredit(change) {
            this.haveCreditInfor = change;
            if(!this.haveCreditInfor) {
                this.$store.dispatch('setAutoTopup', false)
            }
        },
        creditCardCallBack(response) {
            if (response && response.setupIntent) {
                return stripeApi
                    .saveStripeSetupIntent({
                        setup_intent_id: response.setupIntent.id,
                    })
                    .then(response => {
                        if (
                            response &&
                            response.data &&
                            response.data.saveSetupIntent &&
                            response.data.saveSetupIntent.status === 'success'
                        ) {
                            this.haveCreditInfor = true
                            appService.presentAlert({
                                header: 'Account settings',
                                message: 'Credit card information successfully saved to Stripe',
                                buttons: ['OK'],
                            })
                        }
                    })
            }
        },
    },
}
</script>
<style scoped>
ion-content {
    overflow: visible;
}
</style>
