<template>
    <ion-card>
        <ion-card-header>
            <ion-card-title class="card-title-style">{{ $t('favorite-operator') }}</ion-card-title>
        </ion-card-header>
        <ion-card-content>
            <div class="row-content">
                <ion-label color="primary" v-if="favOperator" class="text-body2-style op-name">{{
                    favOperator.name
                }}</ion-label>
                <ion-button
                    fill="clear"
                    size="small"
                    v-if="favOperator && favOperator.website"
                    :href="favOperator.website"
                >
                    <ion-icon class="fb-icon" :icon="globeOutline"></ion-icon>
                </ion-button>
            </div>
            <div class="row-content">
                <ion-label class="ion-text-capitalize text-body2-style">{{ $t('get-notification-from') }}</ion-label>
                <ion-toggle
                    color="primary"
                    class="toggle-notification"
                    mode="md"
                    :checked="notiFromFav"
                    @click="onToggleChange($event.target.checked)"
                    ref="toggleNotiFromOperatorFav"
                ></ion-toggle>
            </div>
        </ion-card-content>
    </ion-card>
</template>
<script>
import { mapState } from 'vuex'
import favApi from '../../util/apis/favorite'
import { globeOutline } from 'ionicons/icons';
import fcmService from "../../util/services/fcmService";
export default {
    name: 'FavoriteOperatorCard',
    data: function() {
        return {
            globeOutline
        }
    },
    computed: {
        ...mapState({
            favOperatorId: state => (state.user ? state.user.fav_operator_id : null),
            favOperator: state => {
                return state.user && state.user.favorite_operator
            },
            notiFromFav: state => (state.user ? state.user.notification_from_fav_operator : null),
        }),
    },
    methods: {
        async changeOperatorNoti(value) {
              const notificationFromFav = await favApi.notificationFromFav({
                notification_from_fav: value,
            })
            if (
                notificationFromFav &&
                notificationFromFav.data &&
                notificationFromFav.data.notificationFromFav.status === 'Success'
            ) {
                this.$store.dispatch('setNotiFromOperator', value)
                this.$refs.toggleNotiFromOperatorFav.$el.checked = value
            }
        },
        async onToggleChange(checked) {
             // check enable notifications
        if(checked === false) {
             if(await fcmService.registerPush(true)) {
                 await this.changeOperatorNoti(!checked);
             } else {
                 this.$refs.toggleNotiFromOperatorFav.$el.checked = false;
             }
        } else {
            await this.changeOperatorNoti(!checked);
        }
          
        },
    },
}
</script>
<style scoped>
.row-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.op-name {
    font-weight: bold;
}
.fb-icon {
    font-size: 30px;
}
.toggle-notification {
    --padding-end: 0px;
    padding-right: 0px;
}
ion-button {
    --padding-start: 0px;
    --padding-end: 0px;
}
</style>
