<template>
    <ion-card>
        <ion-card-header>
            <ion-card-title class="card-title-style">{{ $t('app-information') }}</ion-card-title>
        </ion-card-header>
        <ion-card-content class="card-content">
            <ion-label class="text-body2-style bottom-space">{{ $t('version') }} {{ version }}</ion-label>
            <ion-label class="text-body2-style bottom-space">{{ $t('api-version') }} {{ apiVersion }}</ion-label>
            <ion-label class="text-body2-style bottom-space">{{ $t('developed-by') }}</ion-label>
            <ion-label class="text-body2-style last-line">
                {{ $t('visit-tme') }}
                <!-- <ion-button href="https://www.transportme.com.au" target="_blank" fill="clear"> -->
                    <a href="https://www.transportme.com.au" target="_blank" class="text-body2-style default-color"> transportme.com.au</a>
                <!-- </ion-button> -->
            </ion-label>
        </ion-card-content>
    </ion-card>
</template>
<script>
import appSrv from '../../util/services/appService'
export default {
    name: 'AppInformationCard',
    data: function() {
        return {
            version: null,
            apiVersion: null,
        }
    },
    mounted: async function() {
        const appInfor = await appSrv.getAppInfo()
        this.version = `${appInfor.version}` + (appInfor.build ? ` (${appInfor.build})`: '')
        this.apiVersion = await appSrv.getApiVersion()
    },
}
</script>
<style scoped>
.row-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.card-content {
    display: flex;
    flex-direction: column;
}
.last-line {
    white-space: nowrap;
}
.default-color {
    color: var(--ion-color-primary)
}
.bottom-space {
    margin-bottom: 8px;
}
ion-button::part(native) {
    padding-left: 0px;
    padding-bottom: 10px;
    text-transform: lowercase;
}
</style>
