<template>
    <ion-card>
        <ion-card-header>
            <ion-card-title class="card-title-style">
                {{ $t('credit-card') }}
            </ion-card-title>
        </ion-card-header>

        <ion-card-content>
            <div class="row-btn">
                <ion-button @click="remove" v-if="showCreatedCreditInfor" fill="clear">
                    <ion-label color="primary" class="text-space-right ion-text-capitalize button-text"
                        >remove</ion-label
                    >
                </ion-button>
                <ion-button @click="openCheckoutModal(showCreatedCreditInfor ? 'update' : 'create')" fill="clear">
                    <ion-label color="primary" class="ion-text-capitalize button-text">
                        {{ showCreatedCreditInfor ? $t('update') : $t('link-card') }}
                    </ion-label>
                </ion-button>
            </div>
        </ion-card-content>
    </ion-card>
</template>
<script>
import StripeDirectDebitModal from '../../views/modal/StripeDirectDebitModal'
import { modalController } from '@ionic/vue'
import appService from '../../util/services/appService'
import stripeApi from '../../util/apis/stripe'
import smartcardApi from '../../util/apis/smartcard'

export default {
    name: 'CreditInforCard',
    props: {
        user: Object,
        haveCreditInfor: Boolean,
        creditCardCallBack: Function,
    },
    data: function () {
        return {}
    },
    computed: {
        showCreatedCreditInfor() {
            return this.haveCreditInfor
        },
    },
    methods: {
        remove() {
            return appService.presentAlert({
                header: 'Credit card',
                message: 'Did you want to remove linked card? Auto top-up will also be turned off.',
                buttons: [
                    {
                        text: 'Cancel',
                        role: 'cancel',
                    },
                    {
                        text: 'OK',
                        handler: async () => {
                            const removeRes = await smartcardApi.removeLinkedCard()
                            if (removeRes.data.removeLinkedCard.status === 'success') {
                                this.$emit('change-credit', false)
                                return appService.presentAlert({
                                    header: 'Credit card',
                                    message: 'Credit card was removed successfully',
                                    buttons: ['OK'],
                                })
                            }
                        },
                    },
                ],
            })
        },
        modalCloseHandler(response) {
            console.log('##from settings with love', response)
            this.creditCardCallBack(response)
        },
        async openCheckoutModal(action) {
            const props = {
                action,
            }
            if (action === 'update') {
                const stripeDirectDebitCreated = await stripeApi.getFirstStripeDirectDebit()
                props.stripeDirectDebit = stripeDirectDebitCreated.data.getFirstStripeDirectDebit
            }

            let modal = await modalController.create({
                component: StripeDirectDebitModal,
                cssClass: 'direct-debit-modal-class',
                componentProps: {
                    ...props,
                },
            })

            // show the modal
            await modal.present()

            // wait to see if i get a response
            let modalResponse = await modal.onDidDismiss()

            // when dismissed by clicking outside of modal,
            // data is undefined so we do not handle it
            modalResponse.data && this.modalCloseHandler({ ...modalResponse.data, action })
        },
    },
}
</script>
<style scoped>
.row-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    margin-bottom: 20px;
}

ion-button {
    --background: none;
    --padding-start: none;
}
.button-text {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-transform: capitalize;
    cursor: pointer;
}
.row-btn {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.text-space-right {
    margin-right: 12px;
}
</style>
