<template>
    <ion-card>
        <ion-card-header>
            <ion-card-title class="card-title-style">{{ $t('notification-card-tt') }}</ion-card-title>
        </ion-card-header>
        <ion-card-content>
            <div class="row-content">
                <ion-label class="ion-text-capitalize text-body2-style">{{ $t('smartcard-low-balance') }}</ion-label>
                <ion-toggle
                    ref="toggleNotiCardLowBalance"
                    @click="onToggleNotiCardLowBalanceChange($event.target.checked)"
                    color="primary"
                    class="toggle-notification"
                    mode="md"
                    :checked="smartcardLowBalanceNotification"
                ></ion-toggle>
            </div>
            <div class="row-content">
                <ion-label class="ion-text-capitalize text-body2-style">{{ $t('bus_delays') }}</ion-label>
                <ion-toggle
                    ref="toggleNotiBusDelays"
                    @click="onToggleNotiBusDelaysChange($event.target.checked)"
                    color="primary"
                    class="toggle-notification"
                    mode="md"
                    :checked="busDelaysNotification"
                ></ion-toggle>
            </div>
        </ion-card-content>
    </ion-card>
</template>
<script>
import { mapState } from 'vuex'
import notificationApi from '../../util/apis/notification'
import fcmService from '../../util/services/fcmService'

export default {
    name: 'NotificationSettingCard',
    data: function() {
        return {}
    },
    computed: {
        ...mapState({
            smartcardLowBalanceNotification: state =>
                state.user ? state.user.smartcard_low_balance_notification : null,
            busDelaysNotification: state => state.user ? state.user.bus_delays_notification : null,
        }),
    },
    methods: {
        async changeNotification(value, refs, type) {
            if (type == 'smartcardBalance') {
                await this.setSmartcardLowBalanceReceiveNotification(value, refs)
            } else if (type == 'busDelay') {
                await this.setBusDelayNotification(value, refs)
            }
        },
        async setSmartcardLowBalanceReceiveNotification(value, refs) {
            const response = await notificationApi.setSmartcardLowBalanceReceiveNotification({
                receive_notification: value,
            })
            if (response.data && response.data.setSmartcardLowBalanceReceiveNotification.status === 'Success') {
                this.$store.dispatch('setCardLowBalanceNotification', value)
                refs.$el.checked = value
            }
        },
        async setBusDelayNotification(value, refs) {
             const response = await notificationApi.setBusDelayNotification({
                receive_notification: value,
            })
            if (response.data && response.data.setBusDelayNotification.status === 'Success') {
                this.$store.dispatch('setBusDelayNotification', value)
                refs.$el.checked = value
            }
        },
        async onToggleChange(checked, refs, type) {
            // check enable notifications
            if (checked === false) {
                if (await fcmService.registerPush(true)) {
                    await this.changeNotification(!checked, refs, type)
                } else {
                    refs.$el.checked = false
                }
            } else {
                await this.changeNotification(!checked, refs, type)
            }
        },
        async onToggleNotiCardLowBalanceChange(checked) {
            await this.onToggleChange(checked, this.$refs.toggleNotiCardLowBalance, 'smartcardBalance')
        },
        async onToggleNotiBusDelaysChange(checked) {
            await this.onToggleChange(checked, this.$refs.toggleNotiBusDelays, 'busDelay')
        },
    },
}
</script>
<style scoped>
.row-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.op-name {
    font-weight: bold;
}
.fb-icon {
    font-size: 30px;
}
.toggle-notification {
    --padding-end: 0px;
    padding-right: 0px;
}
ion-button {
    --padding-start: 0px;
    --padding-end: 0px;
}
</style>
